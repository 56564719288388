@import '../../assets/style/variables.module.scss';

.breadcrumbs {

    display: flex;
    width: 100%;
    padding: 16px 0;
    background-color: rgba(211, 211, 211, 0.303);

    .breadcrumbsInner {

        display: flex;
        gap: 12px;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        padding: 0 150px;

        a {
            color: $--primary-black ;
            text-decoration: none;
            list-style: none;
            font-size: 18px;

        }

        span {
            color: rgb(116, 107, 97);
        }

    }

}

@media screen and (max-width: 768px) {
    .breadcrumbs {
        .breadcrumbsInner {
            gap: 8px;
            padding: 0 120px;
            a {
                font-size: 16px;
            }
        }

    }
}

@media screen and (max-width: 450px) {
    .breadcrumbs {
        .breadcrumbsInner {
            gap: 4px;
            padding: 0 40px;
            a {
                font-size: 14px;
            }
        }

    }
}