@import '../../../assets/style/variables.module.scss';


.infocardWrapper {

    display: flex;
    flex-direction: column;
    padding: 0 52px;
    gap: 24px;
    align-items: flex-start;
    background: url('../../../assets/img/home/infoCard/infoBackground.png');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;

    h4 {

        color: $--primary;
        font-weight: 800;
        font-style: normal;
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 6px;

        &::before {

            content: '';
            width: 4px;
            height: 20px;
            background-color: #da2125;
            position: relative;

        }

    }

    span {

        color: $--primary-black;
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        line-height: 0.6cm;
    }

    button {
        padding: 16px 28px;
        background-color: $--primary;
        color: $--primary-white;
        font-size: 14px;
        letter-spacing: 0.1cm;
    }

}