@import '../../assets/style/variables.module.scss';


.newsDetailWrapper {

    display: flex;
    padding: 52px;

    .nDetailWrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 40px;
        border: 1px solid lightgrey;

        img {

            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 4/1;
        }

        h3 {
            text-align: center;
            margin: 24px 0;
            font-size: 24px;
            font-weight: 600;
            font-style: normal;
            text-transform: uppercase;
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &::after {

                content: '';
                width: 90%;
                height: 1px;
                background-color: rgba(0, 0, 0, 0.294);
                margin-top: 24px;
            }
        }

        .shareSocial {

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
            margin: 0 0 24px 0;

            a {
                text-decoration: none;
                text-transform: uppercase;
                color: red;
            }

        }

        iframe {
            
        }

    }

}

@media screen and (max-width: 560px) {
    .newsDetailWrapper {

        display: flex;
        padding: 12px;

        .nDetailWrap {
            display: flex;
            padding: 20px;

            img {

                aspect-ratio: 10/1;
            }
        }

    }
}