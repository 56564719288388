@import '../../assets/style/variables.module.scss';

.aboutusWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .aboutusInnerWrapper {
        display: flex;
        flex-direction: column;
        padding: 52px;
        width: 80%;

        h3 {
            font-size: 28px;
            font-weight: 400;
            font-style: normal;
            color: $--primary-black;
        }

        table {
            display: flex;
            flex-direction: column;
            border-collapse: collapse;
            gap: 20px;
            border: 1px solid rgba(0, 0, 0, 0.255);
            margin-top: 24px;

            thead {
                tr {
                    th {}

                    td {
                        width: 100%;
                    }
                }
            }

            tbody {
                display: flex;
                flex-direction: column;

                tr {
                    display: flex;
                    border: 1px solid rgba(0, 0, 0, 0.255);

                    th {
                        flex: 2;
                        padding: 16px;

                    }

                    td {
                        flex: 3;
                        padding: 16px;
                        border-left: 1px solid rgba(0, 0, 0, 0.255);

                    }
                }
            }
        }

        h5 {
            margin-top: 24px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.748);
        }

        h6 {
            margin: 12px 24px;
        }

        span {
            margin-top: 12px;
            font-weight: 600;
        }
    }
}

@media screen and (max-width:768px) {
    .aboutusWrapper {
        .aboutusInnerWrapper {
            display: flex;
            flex-direction: column;
            padding: 12px;
            width: 90%;
        }
    }
}

@media screen and (max-width:540px) {
    .aboutusWrapper {
        .aboutusInnerWrapper {
            width: 100%;
        }
    }
}