@import '../../../assets/style/variables.module.scss';

.detailedInfoWrapper {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    padding: 42px 0 0 0;
    gap: 30px;
    background-image: url('../../../assets/img/home/detailedInfo/detailedBackground.png');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;

    .infoWrapperInner {
        display: flex;
        height: 25%;

        .infocardWrapper {
            display: flex;
            flex-direction: column;
            padding: 0 52px;
            gap: 24px;
            align-items: flex-start;
            position: relative;
            height: 100%;
            width: 75%;

            h4 {
                color: $--primary;
                font-weight: 800;
                font-style: normal;
                font-size: 20px;
                display: flex;
                align-items: center;
                gap: 6px;

                &::before {
                    content: '';
                    width: 4px;
                    height: 20px;
                    background-color: #da2125;
                    position: relative;

                }
            }

            span {
                color: $--primary-black;
                font-weight: 500;
                font-style: normal;
                font-size: 16px;
                line-height: 0.6cm;
            }

            button {
                padding: 16px 28px;
                background-color: $--primary;
                color: $--primary-white;
                font-size: 14px;
                letter-spacing: 0.1cm;
            }
        }

        .progressCircular {
            display: flex;
            position: relative;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 25%;

            span {
                position: absolute;
                top: calc(50% - 24px);
                left: calc(50% - 14px);
                // color: $--primary-black;
                color: gray;
                font-size: 18px;
                font-weight: 600;
                font-style: normal;
            }

            h5 {
                color: $--primary-black;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 0.025cm;
            }
        }
    }

    .progressLine {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .progressLineInner {
            display: flex;
            align-items: center;
            gap: 24px;
            width: 100%;

            .pLine {
                flex: 1;
                position: relative;
                margin-right: 20px;

                h6 {
                    position: absolute;
                    top: calc(50% - 9px);
                    color: $--primary-white;
                    font-size: 14px;
                }
            }

            span {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 970px) {

    .detailedInfoWrapper {
        display: flex;
        flex-direction: column;

        .infoWrapperInner {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            width: 100%;
            height: 100%;

            .infocardWrapper {
                width: 100%;
            }

            .progressCircular {
                width: 40%;
            }
        }

        .progressLine {

            .progressLineInner {
                flex-direction: column;
                .pLine {
                    width: 100%;
                    position: relative;
                    margin-right: 0;
                    padding: 0 35px;

                    h6 {
                        position: absolute;
                        top: calc(50% - 5px);
                        color: $--primary-white;
                        font-size: 12px;
                    }
                }
                span {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    text-align: center;
                }
            }
        }

    }
}