@import '../../assets/style/variables.module.scss';


.reportsWrapper {
    width: 100%;

    .reportsInnerWrapper {

        display: flex;
        gap: 20px;
        padding: 52px;
        width: 100%;
        height: 100vh;

        .mapScope {

            flex: 1;
            display: flex;
            flex-direction: column;
            background-image: url('../../assets/img/reports/road.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            position: relative;


        }


        .contentScope {
            flex: 3;

            h3 {}

            .innerContentScope {

                select {

                    option{

                        
                    }

                }

                button {}
            }

            .resultsContent {

                span {}


            }
        }
    }
}