@import '../../assets/style/variables.module.scss';


.gdmWrapper {

    width: 100%;

    .gdmInnerWrapper {

        display: flex;
        flex-direction: column;
        padding: 52px;

        h3 {
            font-size: 32px;
            color: $--primary-black;
            font-style: normal;
            font-weight: 500;
        }

        .mainLineWrapper {

            display: flex;
            flex-direction: column;
            margin-top: 40px;

            h6 {
                font-size: 18px;
                color: $--primary-black;
                font-style: normal;
                font-weight: 300;
            }

            .mainLineInnerWrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                margin-top: 24px;

                .specCardGdm {

                    display: flex;
                    flex-direction: column;
                    background-color: rgba(128, 128, 128, 0.112);
                    align-items: center;
                    justify-content: center;
                    padding: 40px 20px;
                    width: calc((100% / 4) - 12px);
                    min-width: 240px;
                    gap: 12px;

                    img {
                        width: 80px;
                        height: 80px;
                        object-fit: contain;
                    }

                    span {
                        text-align: center;
                        width: 50%;
                    }

                }
            }
        }

        .depotAreaWrapper {

            display: flex;
            flex-direction: column;
            margin-top: 40px;

            &::before {
                content: '';
                width: 100%;
                height: 1px;
                background-color: rgba(140, 7, 7, 0.15);
                margin: 32px 0;
            }

            h6 {
                font-size: 18px;
                color: $--primary-black;
                font-style: normal;
                font-weight: 400;
            }

            .mainLineInnerWrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                margin-top: 24px;

                .specCardGdm {

                    display: flex;
                    flex-direction: column;
                    background-color: rgba(128, 128, 128, 0.112);
                    align-items: center;
                    justify-content: center;
                    padding: 40px 20px;
                    width: calc((100% / 4) - 12px);
                    min-width: 240px;
                    gap: 12px;

                    img {
                        width: 80px;
                        height: 80px;
                        object-fit: contain;
                    }

                    span {
                        text-align: center;
                        width: 50%;
                    }

                }
            }

        }

    }

}