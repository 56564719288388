@import '../../assets/style/variables.module.scss';

.assetImagesWrapper {
    width: 100%;
    .assetImagesInnerWrapper {
        display: flex;
        flex-direction: column;
        padding: 52px;
        gap: 32px;
        h3 {
            color: $--primary-black;
            font-size: 30px;
            font-weight: 500;
            font-style: normal;
        }
        .assetImagesContent {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            gap: 24px;
            button {
                display: flex;
                flex-direction: column;
                align-items: center;
                min-width: 240px;
                width: calc((100% / 3) - 24px);
                &:hover {
                    color: rgb(89, 89, 235);
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 1px solid rgba(0, 0, 0, 0.24);
                    padding: 6px;
                }
                span {

                    padding: 8px 0;
                    font-weight: 400;
                    font-style: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .assetImagesWrapper {
        .assetImagesInnerWrapper {
            padding: 12px;
            gap: 12px;
            h3 {
                font-size: 22px;
            }
        }
    }
}