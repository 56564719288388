@import '../../assets/style/variables.module.scss';

.footer {
    border-top: 1px solid #d4d4d4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // position: fixed;
    // bottom: 0;
    z-index: 101;
    background: $--primary;
    margin-top: 100px;

    .miniLogo {

        position: absolute;
        background-color: $--primary;
        top: -50px;
        padding: 20px 50px;
        border-radius: 25px;

        img {
            width: 50px;
            height: 50px;
        }
    }

    .wrapper {
        width: 100%;
        max-width: $--max-width-desktop;
        padding: 50px 0;

        .footerTop {
            display: flex;

            .footerLogo {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 50px;

                img {
                    width: 75%;
                    height: 100%;
                }
            }

            .necessaryLinks {
                flex: 2;
                display: flex;
                padding: 30px;
                gap: 12px;

                .column {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 12px;

                    h6 {
                        display: flex;
                        flex-direction: column;
                        font-size: 18px;
                        color: $--primary-white;
                        width: 90%;

                        &::after {
                            content: '';
                            display: flex;
                            width: 100%;
                            height: 1px;
                            border-bottom: 1px dashed #d4d4d4;

                        }

                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        li {
                            list-style: none;

                            a {
                                font-size: 14px;
                                text-decoration: none;
                                color: $--primary-white;

                                &:hover {
                                    color: burlywood;
                                    transition: 0.5s all ease;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footerBottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-top: 1px solid gray;

            .socialIcons {

                display: flex;
                gap: 24px;
                padding: 12px;

                a {}

            }

            span {
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                color: $--primary-white;

            }
        }
    }
}

@media screen and (max-width:960px) {

    .footer {
        .wrapper {
            width: 90%;

            .footerTop {
                .footerLogo {
                    display: none;
                }

            }
        }
    }
}

@media screen and (max-width:540px) {

    .footer {

        .wrapper {
            display: flex;
            flex-direction: column;

            .footerTop {
                flex-direction: column;
                

                .necessaryLinks {

                    flex-direction: column;
                    justify-content: center;
                    gap: 40px;

                }
            }
        }
    }
}