@import '../../../assets/style/variables.module.scss';

.containerNewsletter {
    display: flex;
    background-color: $--primary;
    padding: 52px;
    align-items: center;
    justify-content: space-around;

    .containerInnerNewsletter {

        display: flex;
        flex-direction: column;
        gap: 40px;

        h5 {
            font-size: 32px;
            color: $--primary-white;
        }

        span {
            font-size: 18px;
            color: $--primary-white;
        }

    }

    button {
        padding: 16px 24px;
        border-radius: 12px;
        background-color: #da2125;
        color: $--primary-white;
        text-transform: uppercase;
    }

}

@media screen and (max-width: 560px) {

    .containerNewsletter {
        display: flex;
        flex-direction: column;
        padding: 32px;
        gap: 20px;

        .containerInnerNewsletter {

            h5 {
                text-align: center;
                font-size: 24px;
                color: $--primary-white;
            }

            span {
                text-align: center;
                font-size: 14px;
                color: $--primary-white;
            }

        }

        button {
            font-size: 14px;
        }

    }

}