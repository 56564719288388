@import '../../assets/style/variables.module.scss';

.stationsWrapper {

    width: 100%;


    .stationsInnerWrapper {

        padding: 52px 100px;

        .verticalTabContainer {

            display: flex;
            gap: 20px;


            .verticalTabButtons {
                display: flex;
                flex-direction: column;
                flex: 1;
                background-color: rgba(128, 128, 128, 0.107);
                border-radius: 8px;

                .verticalTabTopContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 12px;
                    gap: 4px;
                    background-color: $--primary;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;

                    img {
                        width: 32px;
                        height: 32px;
                        object-fit: cover;
                    }

                    span {
                        font-weight: 600;
                        font-size: 16px;
                        color: $--primary-white;
                    }
                }

                button {

                    width: 100%;
                    padding: 12px 24px;
                    color: $--primary-black;
                    font-size: 16px;
                    font-weight: 700;
                    text-align: start;
                    display: flex;
                    flex-direction: column;

                    &.active {
                        color: #da2125;
                    }

                    &::after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: rgba(128, 128, 128, 0.29);
                        margin-top: 12px;
                    }
                }
            }

            .verticalTabContent {

                flex: 2;
                padding: 12px 24px;

                .innerTabContent {

                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    color: $--primary-black;
                    font-size: 18px;
                    text-align: start;
                    letter-spacing: 0.01cm;
                    line-height: 0.65cm;

                    &::before {
                        content: attr(data-text);
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 2cm;
                    }
                }

            }
        }
    }
}

@media screen and (max-width: 960px) {
    .stationsWrapper {
        .stationsInnerWrapper {
            padding: 32px 50px;

            .verticalTabContainer {
                gap: 10px;

                .verticalTabButtons {
                    .verticalTabTopContainer {
                        padding: 6px;
                    }

                    button {
                        padding: 12px 16px;

                        &.active {
                            color: #da2125;
                        }

                        &::after {
                            content: '';
                        }
                    }
                }

                .verticalTabContent {
                    padding: 8px 16px;

                    .innerTabContent {
                        &::before {}
                    }
                }
            }
        }
    }
}

@media screen and (max-width:670px) {
    .stationsWrapper {
        .stationsInnerWrapper {
            padding: 12px 16px;
            .verticalTabContainer {
                display: flex;
                flex-direction: column-reverse;
                .verticalTabButtons {
                    .verticalTabTopContainer {
                        padding: 12px;
                        gap: 4px;
                    }
                }
                .verticalTabContent {
                    .innerTabContent {
                        line-height: 0.6cm;
                        &::before {
                            content: attr(data-text);
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 0.7cm;
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
    }
}