@import '../../../assets/style/variables.module.scss';


.containerNews {
    display: flex;
    flex-direction: column;
    padding: 52px;
    gap: 50px;
    background-image: url('../../../assets/img/home/news/newsbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .contentTitle {

        display: flex;
        align-items: center;
        justify-content: center;

        h4 {
            font-size: 30px;
            color: $--primary;
            font-style: normal;
            font-weight: 700;
        }

    }

    .containNews {

        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 20px;

        .subNews {

            display: flex;
            flex-direction: column;
            width: calc(50% - 20px);
            background-color: #ededed;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
            position: relative;

            .newsOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.31);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {

                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            h6 {
                margin-top: 12px;
                padding: 0 16px;
                font-weight: 500;
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            }

            span {
                padding: 0 12px;
                margin: 8px 4px;
                font-size: 14px;
                font-weight: 300;
            }
        }

        .subBigNews {

            display: flex;
            flex-direction: column;
            width: calc(33% - 10px);
            background-color: #ededed;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
            position: relative;

            .newsOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {

                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            h6 {
                margin-top: 12px;
                padding: 0 16px;
                font-weight: 500;
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            }

            span {
                padding: 0 12px;
                margin: 8px 4px;
                font-size: 14px;
                font-weight: 300;
            }

        }
    }
}

@media screen and (max-width: 960px) {

    .containerNews {
        padding: 32px;
        gap: 30px;

        .containNews {

            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 20px;

            .subNews {

                display: flex;
                flex-direction: column;
                width: 100%;

            }

            .subBigNews {

                display: flex;
                flex-direction: column;
                width: 100%;

            }
        }
    }

}