@import '../../assets/style/variables.module.scss';

.tenderWrapper {
    width: 100%;

    .tenderInnerWrapper {

        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 52px;

        h3 {

            font-size: 28px;
            font-weight: 400;
            font-style: normal;
            color: $--primary-black;

        }

        table {
            display: flex;
            flex-direction: column;
            border-collapse: collapse;
            gap: 20px;
            border: 1px solid rgba(0, 0, 0, 0.255);

            thead {

                tr {

                    th {}

                    td {
                        width: 100%;
                    }

                }

            }

            tbody {

                tr {

                    th {
                        padding: 16px;
                        border: 1px solid rgba(0, 0, 0, 0.255);

                    }

                    td {
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.251);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .tenderWrapper {
        .tenderInnerWrapper {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 12px;
        }
    }
}