@import '../../assets/style/variables.module.scss';

.assetmediaDetailWrapper {

    width: 100%;

    .amInnerDetailWrapper {

        display: flex;
        flex-direction: column;
        padding: 52px;
        gap: 30px;

        h4 {
            color: $--primary-black;
            font-style: normal;
            font-size: 18px;
        }

        .amInnerDetailImages {

            display: flex;
            flex-wrap: wrap;
            gap: 24px;

            button {
                display: flex;
                min-width: 240px;
                width: calc((100% / 3) - 24px);


                img {

                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 1px solid rgba(0, 0, 0, 0.248);
                    padding: 6px;
                }
            }

        }

    }

}