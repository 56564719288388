@import '../../assets/style/variables.module.scss';


.yapimWrapper {

    width: 100%;

    .yapimInnerWrapper {
        display: flex;
        flex-direction: column;
        padding: 52px;

        h3 {
            font-size: 32px;
            color: $--primary-black;
            font-weight: 600;
            font-style: normal;
            text-transform: uppercase;
        }

        .yapimContent {

            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 30px;

            h5 {
                font-size: 18px;
                color: $--primary-black;
                font-weight: 500;
                font-style: normal;
                margin-bottom: 12px;
            }

            h6 {
                font-size: 18px;
                color: $--primary-black;
                font-weight: 400;
                font-style: normal;
                line-height: 0.7cm;
            }

        }

        table {
            display: flex;
            flex-direction: column;
            border-collapse: collapse;
            gap: 20px;
            border: 1px solid rgba(0, 0, 0, 0.255);
            margin-top: 24px;

            thead {
                tr {
                    th {}

                    td {
                        width: 100%;
                    }
                }
            }

            tbody {
                display: flex;
                flex-direction: column;

                tr {
                    display: flex;
                    border: 1px solid rgba(0, 0, 0, 0.255);

                    th {
                        flex: 2;
                        padding: 16px;

                    }

                    td {
                        flex: 3;
                        padding: 16px;
                        border-left: 1px solid rgba(0, 0, 0, 0.255);

                    }
                }
            }
        }

        h6 {
            font-size: 18px;
            color: $--primary-black;
            font-weight: 400;
            font-style: normal;
            line-height: 0.7cm;
        }
    }

}