@import '../../assets/style/variables.module.scss';

.contactMainWrapper {

    display: flex;
    gap: 24px;
    padding: 100px 60px;
    flex-wrap: wrap;

    .mapContainer {
        display: flex;
        gap: 20px;

        .mapInnerContainer {
            display: flex;
            flex-direction: column;
            flex: 1;
            

            h3 {

                color: $--primary;
                font-size: 32px;
                font-weight: 600;
                font-style: normal;

            }

            .columnContact1 {

                padding: 12px;
                display: flex;
                align-items: center;
                gap: 12px;


                span {

                    font-size: 16px;
                    font-weight: 400;
                    font-style: normal;
                    min-width: 300px;

                }

                a {
                    text-decoration: none;
                    list-style: none;
                    color: gray;
                }

            }

        }

        .innerForm {
            border-radius: 5px;
            background-color: #f2f2f2a0;
            padding: 20px;
            flex: 1;
            min-width: 300px;

            p {
                text-align: start;
                font-weight: 500;
                font-size: 24px;
                color: $--primary;
                margin-bottom: 32px;
            }

            form {


                label {}

                input[type=text],
                input[type=email],
                select,
                textarea {
                    width: 100%;
                    padding: 12px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-sizing: border-box;
                    margin-top: 6px;
                    margin-bottom: 16px;
                    resize: vertical;
                }

                input[type=submit] {
                    background-color: #4CAF50;
                    color: white;
                    padding: 12px 20px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }

                input[type=submit]:hover {
                    background-color: #45a049;
                }

                textarea {}

            }

        }


    }


}