@import '../../assets/style/variables.module.scss';

.preloader {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 1000;

  .logo {
    width: 240px;
    margin-bottom: 60px;
  }
}

.loader {
  animation: loader 5s cubic-bezier(0.8, 0, 0.2, 1) infinite;
  height: 100px;
  width: 100px;
}

@keyframes loader {
  90% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.top {
  animation: top 5s linear infinite;
  border-top: 50px solid $--primary-yelllow;
  border-right: 50px solid transparent;
  border-left: 50px solid transparent;
  height: 0px;
  width: 1px;
  transform-origin: 50% 100%;
}

@keyframes top {
  90% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

.bottom {
  animation: bottom 5s linear infinite;
  border-right: 50px solid transparent;
  border-bottom: 50px solid $--secondary-yelllow;
  border-left: 50px solid transparent;
  height: 0px;
  width: 1px;
  transform: scale(0);
  transform-origin: 50% 100%;
}

@keyframes bottom {
  10% {
    transform: scale(0);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.line {
  animation: line 5s linear infinite;
  border-left: 1px dotted $--secondary-yelllow;
  height: 0px;
  width: 0px;
  position: absolute;
  top: 50px;
  left: 50px;
}

@keyframes line {
  10% {
    height: 50px;
  }

  100% {
    height: 50px;
  }
}