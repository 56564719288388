@import '../../assets/style/variables.module.scss';


.suggestionWrapper {

    width: 100%;

    .suggestionInnerWrapper {

        display: flex;
        flex-direction: column;
        padding: 52px;
        gap: 30px;

        h3{
            font-size: 32px;
        }

        .innerForm {
            border-radius: 5px;
            background-color: #f2f2f2a0;
            padding: 20px;
            flex: 1;
            min-width: 300px;

            p {
                text-align: start;
                font-weight: 500;
                font-size: 24px;
                color: $--primary;
                margin-bottom: 32px;
            }

            form {


                label {}

                input[type=text],
                input[type=email],
                input[type=tel],
                select,
                textarea {
                    width: 100%;
                    padding: 12px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    box-sizing: border-box;
                    margin-top: 6px;
                    margin-bottom: 16px;
                    resize: vertical;
                }

                input[type=submit] {
                    background-color: #4CAF50;
                    color: white;
                    padding: 12px 20px;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                }

                input[type=submit]:hover {
                    background-color: #45a049;
                }

                textarea {}

            }

        }

        h5{
            font-size: 15px;
        }

    }

}