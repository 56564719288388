@import '../../assets/style/variables.module.scss';

.sliderWrapper {
    width: 100%;
    height: 100vh;
    position: relative;

    .slider {
        position: relative;

        &>div>div {
            display: flex;
        }

        .imageWrapper {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                width: 100%;
                max-height: calc(100vh - 150px);
                object-fit: cover;
                object-position: center;
            }

            .imageWrapperInfo {

                position: absolute;
                bottom: 15%;
                left: 10%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
                background: linear-gradient(90deg, rgba(221, 29, 33, 0) 0%, rgba(0, 0, 0, 0.582) 35%, rgba(0, 0, 0, 0.559) 65%, rgba(221, 29, 33, 0) 100%);
                padding: 25px;
                z-index: 9;

                h3 {
                    font-style: normal;
                    font-size: 28px;
                    color: $--primary-white;
                }

                span {
                    font-style: normal;
                    font-size: 20px;
                    color: $--primary-white;
                }

                a {

                    padding: 16px 24px;
                    background-color: $--primary;
                    font-style: normal;
                    font-size: 14px;
                    color: $--primary-white;
                    cursor: pointer;

                    &:hover {

                        color: bisque;
                        transition: 0.4s all ease;

                    }

                }

            }
        }


    }

}

@media only screen and (max-width: 960px) {
    .sliderWrapper {
        height: 530px;
        .slider {
            .imageWrapper {
                .imageWrapperInfo {
                    display: none;
                }
            }
        }
    }
}