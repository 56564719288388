@import '../../../assets/style/variables.module.scss';


.underHeaderWrapper {

    display: flex;
    width: 100%;
    gap: 20px;
    padding: 0 32px;
    margin: 40px 0;
    flex-wrap: wrap;
    justify-content: center;

    .wrapperInner {

        display: flex;
        flex-direction: column;
        background-color: #F4F4F480;
        padding: 20px;
        gap: 16px;
        align-items: center;
        width: 240px;

        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
        }

        span {
            font-size: 16px;
            font-style: normal;
            text-align: center;
        }
    }
}

// @media screen and (max-width: 1200px) {

//     .underHeaderWrapper {
//         flex-wrap: wrap;

//         .wrapperInner {

            
           
//         }
//     }

// }

@media screen and (max-width: 570px) {

    .underHeaderWrapper {
        width: 100%;
        padding: 0 50px;
        .wrapperInner {

            width: 100%;
            padding: 20px 0;
        }
    }

}