@import '../../assets/style/variables.module.scss';

.customnavbarNew {
    padding-bottom: 60px;
    display: flex;
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 9;

    .mobileMenu {
        display: none;
    }

    // .logoNew {
    //     flex: 1;
    //     height: 140px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: #143f68;
    //     padding: 25px 0;
    //     border-bottom-right-radius: 50px;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 25%;
    //     transition: all 1s ease;

    //     img {
    //         width: 80%;
    //         height: 80%;
    //         cursor: pointer;
    //         // aspect-ratio: 3/1;
    //     }
    // }

    .logo {

        flex: 1;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #143f68;
        padding: 25px 0;
        border-bottom-right-radius: 50px;
        transition: all 1s ease;
        position: fixed;
        top: 0;
        left: 0;
        width: 25%;
        transition: all 1s ease;

        img {
            width: 80%;
            height: 80%;
            cursor: pointer;
            // aspect-ratio: 3/1;
        }

    }

    .container {

        flex: 3;
        position: relative;

        .containerTopNew {

            display: none;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease
        }

        .containerTop {
            display: flex;
            align-items: center;
            height: 45%;
            padding: 0 50px;
            gap: 16px;
            justify-content: flex-end;
            opacity: 1;
            transition: opacity 1s ease;

            a {
                font-size: 16px;
                color: $--primary-black;
                text-decoration: none;

            }

            .language {
                position: relative;
                display: flex;

                svg {
                    height: 20px;
                    width: 35px;
                }

                select {
                    position: absolute;
                    opacity: 0;
                }
            }
        }

        .containerBottomNew {

            position: fixed;
            display: flex;
            align-items: center;
            background-color: #da2125;
            top: 0;
            right: 0;
            height: 50px;
            width: calc(100% - 25%);
            padding: 0 50px;
            justify-content: flex-end;
            gap: 12px;

            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2px;

                &:hover {

                    .dropdownThird {

                        display: block;
                    }
                }
            }

            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
            }

            a {
                font-size: 16px;
                color: $--primary-white;
                text-decoration: none;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
                transition: 0.4s all ease;
                position: relative;

                &:hover {
                    background-color: $--primary;

                    .dropdown {

                        display: block;
                    }

                }

                .dropdown {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 45px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        color: $--primary-white;
                        padding: 12px 23px;
                        font-size: 14px;

                        &:hover {

                            .dropdownSecond {

                                display: block;
                            }
                        }

                        .dropdownSecond {
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 0;
                            left: 140px;
                            align-items: center;
                            justify-content: center;
                            background-color: $--primary;

                            a {
                                padding: 16px 26px;
                                width: max-content;
                            }
                        }

                    }
                }

                .dropdownThird {

                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 45px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        padding: 12px 22px;
                        width: max-content;
                    }
                }

            }
        }

        .containerBottom {
            display: flex;
            align-items: center;
            background-color: #da2125;
            height: 55%;
            padding: 0 50px;
            justify-content: flex-end;
            gap: 12px;

            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2px;

                &:hover {

                    .dropdownThird {

                        display: block;
                    }
                }
            }

            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
            }

            a {
                font-size: 16px;
                color: $--primary-white;
                text-decoration: none;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
                transition: 0.4s all ease;
                position: relative;

                &:hover {
                    background-color: $--primary;

                    .dropdown {

                        display: block;
                    }

                }

                .dropdown {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 55px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        color: $--primary-white;
                        padding: 12px 23px;
                        font-size: 14px;

                        &:hover {

                            .dropdownSecond {

                                display: block;
                            }
                        }

                        .dropdownSecond {
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 0;
                            left: 140px;
                            align-items: center;
                            justify-content: center;
                            background-color: $--primary;

                            a {
                                padding: 16px 26px;
                                width: max-content;
                            }
                        }

                    }
                }

                .dropdownThird {

                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 55px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        padding: 12px 27px;
                        width: max-content;
                    }
                }

            }

        }

    }

}

.customnavbar {

    display: flex;
    width: 100%;
    height: 100px;
    position: relative;
    z-index: 9;

    .mobileMenu {
        display: none;
    }

    // .logoNew {
    //     flex: 1;
    //     height: 140px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: #143f68;
    //     padding: 25px 0;
    //     border-bottom-right-radius: 50px;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     width: 25%;
    //     transition: all 1s ease;

    //     img {
    //         width: 80%;
    //         height: 80%;
    //         cursor: pointer;
    //         // aspect-ratio: 3/1;
    //     }
    // }

    .logo {

        flex: 1;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #143f68;
        padding: 25px 0;
        border-bottom-right-radius: 50px;
        transition: all 1s ease;

        img {
            width: 80%;
            height: 80%;
            cursor: pointer;
            // aspect-ratio: 3/1;
        }

    }

    .container {

        flex: 3;
        position: relative;

        .containerTopNew {

            display: none;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s ease
        }

        .containerTop {
            display: flex;
            align-items: center;
            height: 45%;
            padding: 0 50px;
            gap: 16px;
            justify-content: flex-end;
            opacity: 1;
            transition: opacity 1s ease;

            a {
                font-size: 16px;
                color: $--primary-black;
                text-decoration: none;

            }

            .language {
                position: relative;
                display: flex;

                svg {
                    height: 20px;
                    width: 35px;
                }

                select {
                    position: absolute;
                    opacity: 0;
                }
            }
        }

        .containerBottomNew {

            position: fixed;
            display: flex;
            align-items: center;
            background-color: #da2125;
            top: 0;
            right: 0;
            height: 55px;
            width: calc(100% - 25%);
            padding: 0 50px;
            justify-content: flex-end;
            gap: 12px;

            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2px;

                &:hover {

                    .dropdownThird {

                        display: block;
                    }
                }
            }

            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
            }

            a {
                font-size: 16px;
                color: $--primary-white;
                text-decoration: none;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
                transition: 0.4s all ease;
                position: relative;

                &:hover {
                    background-color: $--primary;

                    .dropdown {

                        display: block;
                    }

                }

                .dropdown {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 55px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        color: $--primary-white;
                        padding: 12px 23px;
                        font-size: 14px;

                        &:hover {

                            .dropdownSecond {

                                display: block;
                            }
                        }

                        .dropdownSecond {
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 0;
                            left: 140px;
                            align-items: center;
                            justify-content: center;
                            background-color: $--primary;

                            a {
                                padding: 16px 26px;
                                width: max-content;
                            }
                        }

                    }
                }

                .dropdownThird {

                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 55px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        padding: 12px 27px;
                        width: max-content;
                    }
                }

            }
        }

        .containerBottom {
            display: flex;
            align-items: center;
            background-color: #da2125;
            height: 55%;
            padding: 0 50px;
            justify-content: flex-end;
            gap: 12px;

            .arrow {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2px;

                &:hover {

                    .dropdownThird {

                        display: block;
                    }
                }
            }

            img {
                width: 32px;
                height: 32px;
                object-fit: cover;
            }

            a {
                font-size: 16px;
                color: $--primary-white;
                text-decoration: none;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 8px;
                transition: 0.4s all ease;
                position: relative;

                &:hover {
                    background-color: $--primary;

                    .dropdown {

                        display: block;
                    }

                }

                .dropdown {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 55px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        color: $--primary-white;
                        padding: 12px 23px;
                        font-size: 14px;

                        &:hover {

                            .dropdownSecond {

                                display: block;
                            }
                        }

                        .dropdownSecond {
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 0;
                            left: 140px;
                            align-items: center;
                            justify-content: center;
                            background-color: $--primary;

                            a {
                                padding: 16px 26px;
                                width: max-content;
                            }
                        }

                    }
                }

                .dropdownThird {

                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 55px;
                    left: 0;
                    align-items: center;
                    justify-content: center;
                    background-color: $--primary;

                    a {
                        padding: 12px 27px;
                        width: max-content;
                    }
                }

            }

        }

    }
}

@media screen and (max-width: 960px) {
    .customnavbarNew {
        display: none;
    }

    .customnavbar {

        .containerTopNew{
            display: none;
        }

        .mobileMenu {

            display: flex;
            width: 100%;
            height: 60px;
            align-items: center;
            justify-content: flex-end;
            padding: 0 20px;
            background-color: #143f68;

        }

        .customMobileMenu {
            display: flex;
            flex-direction: column;
            width: 70%;
            background-color: #143f68;
            height: 100%;
            padding: 25px;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;

            button {

                position: absolute;
                display: flex;
                justify-content: flex-end;
                border: 1px solid white;
                padding: 6px;
                border-radius: 100px;
                width: fit-content;
                right: 30px;
                top: 20px;
            }

            .menuList {
                padding-top: 50px;

                .primaryUl {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .primaryLi {
                        padding: 12px 0;
                        color: $--primary-white;

                        .secondaryUl {

                            display: none;

                            &:hover {
                                display: flex;
                            }

                            .secondaryLi {
                                display: none;

                                .thirdUl {
                                    display: none;

                                    .thirdLi {}

                                }

                            }

                        }
                    }
                }

            }

            .languageCard {
                display: flex;
            }
        }

        .logo {
            display: none;
        }

        .container {
            display: none;
        }
    }
}