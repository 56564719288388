@import '../../assets/style/variables.module.scss';

.newsWrapper {

    display: flex;
    flex-direction: column;
    width: 100%;

    .containNews {

        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 20px;
        padding: 52px;
        margin-top: 24px;
        justify-content: center;
        position: relative;

        .subBigNews {

            display: flex;
            flex-direction: column;
            width: calc(33% - 10px);
            min-width: 240px;
            background-color: #ededed;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
            position: relative;

            .newsOverlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.31);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {

                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            h6 {
                margin-top: 12px;
                padding: 0 16px;
                font-weight: 500;
            }

            span {
                padding: 0 12px;
                margin: 8px 4px;
                font-size: 14px;
                font-weight: 300;
            }

        }
    }

}

@media screen and (max-width:760px) {
    .newsWrapper {
        .containNews {
            padding: 26px;
            margin-top: 12px;

            .subBigNews {
                width: 100%;
                min-width: 240px;
            }
        }

    }
}