@import '../../assets/style/variables.module.scss';

.pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
}

.contactCard {
    position: absolute;
    width: 100vw;
    height: 345px;
    background: $--primary-yelllow;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &::before {
        content: '';
        width: 150%;
        height: 610px;
        position: absolute;
        background: white;
        left: -510px;
        bottom: -525px;
        border-radius: 50%;
    }

    h1 {
        position: absolute;
        bottom: 100px;
        width: 100%;
        max-width: $--max-width-desktop;
        font-size: 42px;
        font-weight: 600;
        color: white;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.active {
    transform: rotate(180deg);
}

@media only screen and (max-width: 960px) {
    .contactCard {
        height: 230px;

        h1 {
            font-size: 26px;
            width: 90%;
            bottom: 60px;
        }

        &::before {
            left: -150px;
            height: 50%;
            bottom: -75px;
        }
    }
}