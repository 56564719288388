@font-face {
  font-family: 'Copperplate9';
  src: url('../fonts/copperplate/CopperplateGothicStd-33BC.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-33BC.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate3';
  src: url('../fonts/copperplate/CopperplateGothicStd-32BC.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-32BC.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate4';
  src: url('../fonts/copperplate/CopperplateGothicStd-32AB.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-32AB.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate6';
  src: url('../fonts/copperplate/CopperplateGothicStd-31BC.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-31BC.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate8';
  src: url('../fonts/copperplate/CopperplateGothicStd-31AB.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-31AB.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate5';
  src: url('../fonts/copperplate/CopperplateGothicStd-30BC.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-30BC.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate7';
  src: url('../fonts/copperplate/CopperplateGothicStd-30AB.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-30AB.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate1';
  src: url('../fonts/copperplate/CopperplateGothicStd-29BC.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-29BC.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Copperplate2';
  src: url('../fonts/copperplate/CopperplateGothicStd-29AB.woff2') format('woff2'),
    url('../fonts/copperplate/CopperplateGothicStd-29AB.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}


$--Copperplate: 'Copperplate2', sans-serif;
$--primary-yelllow: #f8c12c;
$--secondary-yelllow: #d3a04e;
$--primary-black: #000000;
$--primary-white: #FFFFFF;
$--primary: #143f68;

$--max-width-desktop: 1400px;

body,
input,
select,
textarea {
  box-sizing: border-box;
  font-family: 'Arial';
}

button {
  background: transparent;
  cursor: pointer;
  font-family: 'Arial';
}